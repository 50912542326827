import _objectWithoutProperties from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["className", "blogArticleData", "pageRoute"];
var __jsx = React.createElement;
/**
 * Represents BlogArticle.
 * @author kkum
 */import React from 'react';
import styled from 'styled-components';
import Heading from '../../atoms/Heading';
import Image from '../../atoms/Image';
import RichText from '../../atoms/RichText/RichText';
import styles from './BlogArticle.style';
import { imageRenditions } from './config';
import Para from '../../atoms/Para';
var BlogArticleDetails = function BlogArticleDetails(_ref) {
  var _blogContent$links, _blogContent$links$en, _blogContent$links2, _blogContent$links2$e, _blogContent$links3, _blogContent$links3$a;
  var className = _ref.className,
    blogArticleData = _ref.blogArticleData,
    pageRoute = _ref.pageRoute,
    others = _objectWithoutProperties(_ref, _excluded);
  if (!blogArticleData) {
    return null;
  }
  var title = blogArticleData.title,
    blogCategory = blogArticleData.blogCategory,
    blogImage = blogArticleData.blogImage,
    blogContent = blogArticleData.blogContent,
    webpageBlog = blogArticleData.webpageBlog,
    blogAuthor = blogArticleData.blogAuthor,
    blogDate = blogArticleData.blogDate;
  var imageSource = blogImage && blogImage.image && blogImage.image.url;
  var content = blogContent && blogContent.json;
  var richTextEmbeddedAsset = blogContent.links && blogContent.links.assets.block || [];
  var author = blogAuthor ? "by ".concat(blogAuthor) : '';
  var dateFormatted = blogDate !== null ? __jsx(React.Fragment, null, __jsx("span", {
    className: "nva-blog-article__date"
  }, new Date(blogDate).toLocaleString('en', {
    timeZone: "GMT",
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  })), __jsx("span", null, " \xB7 ")) : '';
  return __jsx("div", {
    className: "nva-blog-article ".concat(className)
  }, __jsx("div", {
    className: "container"
  }, __jsx(Heading, {
    HeadingType: "h1",
    className: "nva-blog-article__title"
  }, title), __jsx(Heading, {
    HeadingType: "h4",
    className: "nva-blog-article__category"
  }, dateFormatted, blogCategory.categoryName), __jsx(Para, {
    className: "nva-blog-article__author u-body2"
  }, author), __jsx("div", {
    className: "nva-blog-article__image image-wrapper"
  }, __jsx(Image, {
    alt: blogImage.altText,
    title: blogImage.title || blogImage.altText,
    src: imageSource,
    srcSetSettingImg: {
      imageRenditions: imageRenditions
    }
  })), __jsx(RichText, {
    jsonContent: blogContent === null || blogContent === void 0 ? void 0 : blogContent.json,
    entriesContent: blogContent === null || blogContent === void 0 ? void 0 : (_blogContent$links = blogContent.links) === null || _blogContent$links === void 0 ? void 0 : (_blogContent$links$en = _blogContent$links.entries) === null || _blogContent$links$en === void 0 ? void 0 : _blogContent$links$en.block,
    linksContent: blogContent === null || blogContent === void 0 ? void 0 : (_blogContent$links2 = blogContent.links) === null || _blogContent$links2 === void 0 ? void 0 : (_blogContent$links2$e = _blogContent$links2.entries) === null || _blogContent$links2$e === void 0 ? void 0 : _blogContent$links2$e.inline,
    richTextEmbeddedAsset: blogContent === null || blogContent === void 0 ? void 0 : (_blogContent$links3 = blogContent.links) === null || _blogContent$links3 === void 0 ? void 0 : (_blogContent$links3$a = _blogContent$links3.assets) === null || _blogContent$links3$a === void 0 ? void 0 : _blogContent$links3$a.block,
    className: "nva-blog-article__content blog-content"
  })));
};
BlogArticleDetails.defaultProps = {
  className: ''
};
export default styled(BlogArticleDetails).withConfig({
  componentId: "sc-1vyrxm2-0"
})(["", ";"], styles);